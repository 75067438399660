.faq {
    margin-bottom : 30px;
}

.card {
    border : none;
}

.card-header {
    display: flex;
    justify-content: space-between;
    background-color : #f5f5f5;
    color            : #000;
    border-bottom: none !important;
    transition: all .1s;
    padding: 8px 16px !important;
}

.card-header h5, .card-header-active h5 {
    font-family: 'Anton', sans-serif;
}

.card-header-active {
    display: flex;
    justify-content: space-between;
    background-color : #3ccdb0;
    border-bottom: none !important;
    color: #fff;
    position: relative;
    padding: 8px 16px;
    transition: all .1s;
}

.card-minus {
    background      : url("../images/minus.png") no-repeat;
    background-size : cover;
    width           : 30px;
    height          : 30px;
}

.card-add {
    background      : url("../images/add.png") no-repeat;
    background-size : contain;
    width           : 30px;
    height          : 30px;
}

.card-body {
    transition: all .1s;
}