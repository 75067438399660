.footer {
    flex        : 0 0 auto;
    background  : #f7fafc;
    padding-top : 30px;
}

.footer_bank img {
    max-width      : 100%;
    width          : 232px;
    padding-bottom : 10px;
    padding-top    : 10px;
    margin         : 0 auto;
    display        : block;
}

.footer_bottom {
    background : #2b3848;
    color      : #fff;
    text-align : center;
    padding    : 30px;
}

.footer_link {
    background : #1d2631;
    padding    : 11px;
    text-align : center;
}

.footer-up {
    width: 30px;
    height: 30px;
}

.up {
    display : none;
}

@media (min-width: 1200px) {
    .up {
        display : block;
    }

    .up a {
        background    : #00619f2e;
        padding       : 10px;
        border-radius : 8px;
        position      : fixed;
        right         : 50px;
        bottom        : 50px;
        cursor        : pointer;
        z-index       : 11;
        transition    : all 200ms ease;
    }

    .up a:hover {
        background : #273547;
        transition : all 200ms ease;
    }

    .up img {
        width: 20px;
        height: 20px;
    }
}