.prise_body ul li:before {
    content: none;
}

.container {
    padding: 0 30px;
}

.row {
    padding-top: 40px !important;
}

.big_summ1 {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 600;
    padding-right : 6px;
}

.big_summ {
    font-size      : 70px;
    display        : inline-block;
    vertical-align : bottom;
    line-height    : 58px;
    padding-right  : 10px;
}

.name_summ {
    font-size      : 20px;
    display        : inline-block;
    vertical-align : bottom;
}

.prise_body p.pusto {
    height     : 40px;
    background : none;
}

.prise_body ul li {
    padding : 0 10px;
    border  : none;
    margin  : 0;
}

.prise_body p {
    position        : relative;
    display         : flex;
    justify-content : center;
    align-items     : center;
    border-radius   : 38px;
    background      : white;
    text-decoration : line-through;
    font-size       : 25px;
    color           : #727272;
    font-weight     : 600;
}

.prise_body li {
    font-size : 18px;
    display   : flex;
}

.prise_foot1 {
    margin: 0 auto 20px;
}

.prise_block .prise_one1, .prise_block2 .prise_one1, .prise_block3 .prise_one1, .prise_block4
.prise_one1 {
    padding-bottom: 0;
    border-radius: 0 25px 25px 25px;
}

.platinum {
    position: relative;
    z-index: 1 !important;
}

.one, .two, .three {
    position: relative;
}

.one::after, .two::after, .three::after{
    position: absolute;
    top: -15px;
    left: -15px;
    width: 50px;
    height: 50px;
    background-color: #ddd;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    font-size: 40px;
    text-align: center;
    z-index: 11 !important;
}

.one::after {
    content: '1';
    color: #3ccdb0;
}

.two::after {
    content: '2';
    color: #3c4e65;
}

.three::after {
    content: '3';
    color: #82608c;
}

.platinum .big_summ1 {
    color : #3ccdb0;
}

.summ {
    text-align    : center;
    font-size     : 30px;
    border-bottom : 1px solid #ddd;
    padding       : 20px 0;
}

.prise_block .prise_one1, .prise_block2 .prise_one1, .prise_block3 .prise_one1, .prise_block4 .prise_one1 {
    background     : #f3f3f3;
    margin-bottom  : 30px;
    border-radius  : 25px;
    overflow       : hidden;
}

.prise_one1.platinum, .prise_one1.gold, .prise_one1.silver {
    background : #f3f3f3;
}

.prise_block2 {
    background      : url("../images/fon2.jpeg") no-repeat;
    padding         : 59px 0;
    padding-bottom  : 26px;
    background-size : cover;
    margin-bottom   : 70px;
}

.prise_block3 {
    padding        : 59px 0;
    padding-bottom : 26px;
}

.prise_block4 {
    padding        : 59px 0;
    padding-bottom : 26px;
}

.silver .prise_top {
    height          : 137px;
    background      : url("../images/silv1.jpeg") no-repeat;
    background-size : cover;
}

.silver svg.svg-sli {
    fill : #82608c;
}

.silver .pink_but {
    background : #82608c;
}

.silver .big_summ1 {
    color : #82608c;
}

.platinum svg.svg-sli {
    fill : #00c7aa;
}

.prise_one1 ul {
    list-style-type : none;
    padding-left    : 0;
    text-align      : left;
    display         : inline-block;
}

.prise_body {
    text-align    : center;
    margin-top    : 20px;
    margin-bottom : 20px;
}

.prise_foot1 {
    text-align : center;
}

.prise_foot1 .pink_but {
    height    : 48px;
    color     : #fff;
    width     : 197px;
    font-size : 18px;
}

.platinum .pink_but {
    background : #3ccdb0;
}


.platinum .prise_top {
    height          : 137px;
    background      : url("../images/plat1.jpeg") no-repeat;
    background-size : cover;
    text-transform  : uppercase;
}

.prise_top {
    color   : #fff;
    padding : 20px 17px;
}

.gold .prise_top {
    height          : 137px;
    background      : url("../images/gold.jpeg") no-repeat;
    background-size : cover;
}

.gold svg.svg-sli {
    fill : #3c4e65;
}

.gold .pink_but {
    background : #3c4e65;
}

.gold .big_summ1 {
    color : #3c4e65;
}

.prise_body ul {
    padding : 0;
}

.prise_body ul li {
    list-style-type : none;
    padding-right   : 20px;
    position        : relative;
    border-bottom   : 1px solid #fff;
    margin-top      : 10px;
}

.prise_body ul li:last-child {
    border-bottom : 0px;
}

.prise_top h3 {
    text-align : left;
}

.prise_foot {
    text-align : center;
    position   : absolute;
    width      : 100%;
    left       : 0;
    bottom     : -29px;
}

.prise_one1 .pink_but {
    width : 233px;
}


.prise_top h3 {
    text-align     : center;
    text-transform : uppercase;
    font-size      : 25px;
    font-weight    : 500;
    font-family: 'Barlow Condensed', sans-serif;
}

.bubbly-button {
    border                            : none;
    cursor                            : pointer;
    position                          : relative;

    overflow                          : hidden;
    transition                        : transform ease-in 3s, box-shadow ease-in 0.25s;
    animation-name                    : pulse;
    -webkit-animation-name            : pulse;
    animation-duration                : 3s;
    -webkit-animation-duration        : 3s;
    animation-iteration-count         : infinite;
    -webkit-animation-iteration-count : infinite;
    /*box-shadow: 0 2px 25px rgba(255, 0, 130, 0.5);*/
}

.bubbly-button:after {
    background-image                  : linear-gradient(71deg, rgba(255, 255, 255, .0) 43%, rgba(255, 255, 255, 0.64), rgba(255, 255, 255, .0) 70%);
    background-repeat                 : no-repeat;
    background-size                   : 200% 100%, auto, 100% 2px, 100% 2px, 100% 1px, 100% 1px;
    background-position               : 200% 0, 0 0, 0 0, 0 100%, 0 4px, 0 calc(100% - 4px);
    margin-left                       : 60px;
    content                           : " ";
    width                             : 30px;
    height                            : 300px;
    background                        : #ffffffc4;
    box-shadow                        : 0px 0px 22px #fff;
    -webkit-animation-delay           : 0.05s;
    animation-delay                   : 0.05s;
    position                          : absolute;
    left                              : -40px;
    top                               : -150px;
    -webkit-animation-timing-function : ease-in-out;
    animation-timing-function         : ease-in-out;
    transition                        : all 0.1s;
    -moz-transition                   : all 0.1s;
    -webkit-transition                : all 0.1s;
    -o-transition                     : all 0.1s;
    -khtml-transition                 : all 0.1s;
    -ms-transition                    : all 0.1s;
    -webkit-animation-name            : tossing;
    animation-name                    : tossing;
    -webkit-animation-duration        : 3s;
    animation-duration                : 3s;
    -webkit-animation-iteration-count : infinite;
    animation-iteration-count         : infinite;
    transform                         : rotate(45deg);
    -moz-transform                    : rotate(45deg);
    -webkit-transform                 : rotate(45deg);
    -o-transform                      : rotate(45deg);
    -khtml-transform                  : rotate(45deg);
    -ms-transform                     : rotate(45deg);
}

.bubbly-button:focus {
    outline : 0;
}


@media (min-width: 768px) {
    .prise_one1 ul {
        min-height: 189px;
    }

    .summ {
        text-align      : center;
        font-size       : 20px;
        border-bottom   : 1px solid #ddd;
        padding         : 6px 0;
        min-height      : 76px;
        display         : flex;
        justify-content : center;
        align-items     : center;
        flex-wrap       : wrap;
    }

    .prise_body {
        min-height: 240px;
    }

    .prise_one1 ul {
        padding   : 0 19px;
        font-size : 13px;
    }

    .prise_foot1 .pink_but {
        height    : 42px;
        color     : #fff;
        width     : 166px;
        font-size : 15px;
    }
}

@media (min-width: 990px) {

    .prise_body {
        min-height: 150px;
    }

    .prise_one1 ul {
        min-height: 135px;
    }
}

@media (min-width: 1200px) {

    .prise_body {
        min-height: 174px;
    }

    .prise_body ul li {
        padding-bottom : 13px;
    }

    .prise_body ul li {
        padding-bottom : 13px;
    }

    .prise {
        margin-bottom : 30px;
    }

    .prise_one {
        padding : 36px 16px 25px 17px;
    }

    .name_summ {
        line-height : 23px;
        text-align  : left;
    }
}