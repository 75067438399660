@import url('https://fonts.googleapis.com/css2?family=Anton&family=Barlow+Condensed:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:wght@300;400;500;600;700;800&family=Oswald:wght@300;400;500;600;700&display=swap');

* {
    outline : none;
}

body {
    font-family: 'Barlow Condensed', sans-serif;
}

html, body {
    height : 100%;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Barlow Condensed', sans-serif;
}

button:focus {
    outline : none;
}



.wrapper {
    display        : flex;
    flex-direction : column;
    min-height     : 100%;
    margin         : 0 auto;
}

.content {
    flex     : 1 0 auto;
    overflow : hidden;
}

.top {
    position : relative;
    z-index  : 1;
}

header {
    display         : flex;
    align-items     : center;
    justify-content : space-between;
}

#up_top .box_h1 a, .footer .foot_logo a {
    font-size       : 25px;
    color           : #3a2e95;
    text-decoration : none;
    display         : inline-block;
    width           : 100%;
    text-align      : center;
    line-height     : 40px;
}

h1 {
    color       : #3a2e95;
    font-size   : 12px;
    font-weight : 300;
    border-top  : 1px solid #3a2e95;
    padding-top : 5px;
    margin-top  : 0;
    text-align  : center;
    font-family: 'Barlow Condensed', sans-serif;
}

.log_in {
    text-align : right;
}

.log_in a {
    color           : #000;
    font-size       : 16px;
    text-decoration : none;
}

.log_in svg {
    padding-right : 5px;
}

.prise {
    margin-bottom : 50px;
}

.prise_one {
    padding       : 50px 28px;
    color         : #fff;
    position      : relative;
    margin-bottom : 62px;
    min-height    : 555px;
}

.top_banner {
    background-size     : cover;
    background-position : 48% 100%;
    margin-bottom       : 40px;
}

.detailed {
    font-size     : 16px;
    text-align    : center;
    margin-bottom : 17px;
}

.bubbly-button {
    border                            : none;
    cursor                            : pointer;
    position                          : relative;

    overflow                          : hidden;
    transition                        : transform ease-in 3s, box-shadow ease-in 0.25s;
    animation-name                    : pulse;
    -webkit-animation-name            : pulse;
    animation-duration                : 3s;
    -webkit-animation-duration        : 3s;
    animation-iteration-count         : infinite;
    -webkit-animation-iteration-count : infinite;
    text-transform                    : uppercase;
}

.bubbly-button:after {
    background-image                  : linear-gradient(71deg, rgba(255, 255, 255, .0) 43%, rgba(255, 255, 255, 0.64), rgba(255, 255, 255, .0) 70%);
    background-repeat                 : no-repeat;
    background-size                   : 200% 100%, auto, 100% 2px, 100% 2px, 100% 1px, 100% 1px;
    background-position               : 200% 0, 0 0, 0 0, 0 100%, 0 4px, 0 calc(100% - 4px);
    margin-left                       : 60px;
    content                           : " ";
    width                             : 30px;
    height                            : 300px;
    background                        : #ffffffc4;
    box-shadow                        : 0px 0px 22px #fff;
    -webkit-animation-delay           : 0.05s;
    animation-delay                   : 0.05s;
    position                          : absolute;
    left                              : -40px;
    top                               : -150px;
    -webkit-animation-timing-function : ease-in-out;
    animation-timing-function         : ease-in-out;
    transition                        : all 0.1s;
    -moz-transition                   : all 0.1s;
    -webkit-transition                : all 0.1s;
    -o-transition                     : all 0.1s;
    -khtml-transition                 : all 0.1s;
    -ms-transition                    : all 0.1s;
    -webkit-animation-name            : tossing;
    animation-name                    : tossing;
    -webkit-animation-duration        : 3s;
    animation-duration                : 3s;
    -webkit-animation-iteration-count : infinite;
    animation-iteration-count         : infinite;
    transform                         : rotate(45deg);
    -moz-transform                    : rotate(45deg);
    -webkit-transform                 : rotate(45deg);
    -o-transform                      : rotate(45deg);
    -khtml-transform                  : rotate(45deg);
    -ms-transform                     : rotate(45deg);
}

.bubbly-button:focus {
    outline : 0;
}

.pink_but {
    background      : #ff25fc;
    height          : 58px;
    color           : #fff;
    font-weight     : 500;
    width           : 197px;
    border-radius   : 30px;
    display         : inline-flex;
    justify-content : center;
    align-items     : center;
    font-size       : 18px;
    text-decoration : none;
    margin-top      : 0;
    border          : 4px solid #fff;
}

.pink_but:hover {
    text-decoration : none;
    outline         : none;
    color           : #fff;
}

.pulse {
    animation-duration                : 0.5s;
    -webkit-animation-duration        : 0.5s;
    animation-iteration-count         : infinite;
    -webkit-animation-iteration-count : infinite;
}

.pulse, .pulse:active, .pulse:focus, .pulse:hover {
    animation-duration                : 0.5s;
    -webkit-animation-duration        : 0.5s;
    animation-iteration-count         : infinite;
    -webkit-animation-iteration-count : infinite;
}

@keyframes tossing {
    0% {
        transition : .05s linear;
        left       : -20px;
    }
    20% {
        transition : .05s linear;
        left       : 100%;
    }
    100% {
        transition : .05s linear;
        left       : 100%;
    }
}

@keyframes pulse {
    0% {
        -webkit-transform : scale(1.0);
        transform         : scale(1.0);
    }
    50% {
        -webkit-transform : scale(1.1);
        transform         : scale(1.1);
    }
    0% {
        -webkit-transform : scale(1.0);
        transform         : scale(1.0);
    }
}

.lozung {
    text-transform : uppercase;
    background     : #fff;
    padding        : 19px 21px;
    margin-top     : 20px;
    width          : 80%;
    margin         : 0 auto;
    margin-left    : 70px;
    margin-top     : 20px;
}

.lozung h2 {
    color          : #ff0000;
    font-weight    : 300;
    letter-spacing : 4px;
    font-size      : 18px;
    margin-bottom  : 0;
}

.name_cours h2 {
    font-family: 'Barlow Condensed', sans-serif;
    color         : #3b166a;
    text-align    : center;
    font-size     : 25px;
    margin-top    : 79px;
    text-shadow   : 0px 0px 11px #fff;
    margin-bottom : 20px;
}

.name_cours h2 span {
    font-family: 'Barlow Condensed', sans-serif;
    display        : block;
    text-transform : uppercase;
    font-size      : 47px;
    letter-spacing : 4px;
    margin-top     : 13px;
}

.name_cours {
    margin-bottom : 10px;
    text-align    : center;
}

.about_cours_title {
    text-align : center;
}

.about_cours_title h3 {
    color          : #3b166a;
    font-weight    : 300;
    text-transform : uppercase;
    letter-spacing : 3px;
    font-size      : 38px;
}

.about_cours_info p span {
    color : #3b166a;
}

.about_cours_info p {
    letter-spacing : 2px;
    text-align     : center;
    font-size      : 20px;
}

.dostup_baner {
    background      : #e8e8e8;
    display         : flex;
    flex-wrap       : wrap;
    min-height      : 300px;
    justify-content : center;
    align-items     : center;
    position        : relative;
    padding         : 34px 12px;
}

.dostup_baner:before {
    content         : '';
    position        : absolute;
    top             : -76px;
    left            : 17px;
    width           : 200px;
    height          : 100px;
    background-size : contain;
}

.dostup_baner:after {
    content         : '';
    position        : absolute;
    bottom          : -26px;
    right           : -3px;
    width           : 100px;
    background-size : contain;
    height          : 100px;
}

.dostup_baner p {
    color          : #3b166a;
    text-transform : uppercase;
    font-weight    : 600;
    font-size      : 26px;
    text-align     : center;
}

.h3 {
    color          : #3b166a;
    font-weight    : 300;
    text-transform : uppercase;
    letter-spacing : 3px;
    font-size      : 38px;
}

.prog_list ul {
    list-style-type : none;
}

.prog_list ul li {
    letter-spacing : 2px;
    font-size      : 20px;
    margin-bottom  : 15px;
    position       : relative;
}

.prog_list ul li:before {
    content         : '';
    position        : absolute;
    top             : 6px;
    left            : -33px;
    width           : 20px;
    height          : 20px;
    background-size : contain;
}

.protivopok {
    padding         : 65px 0;
    background-size : cover;
    margin-bottom   : 40px;
    padding-bottom  : 55px;
}

.protivopok_wrap h3 {
    text-align    : center;
    margin-bottom : 40px;
    font-size     : 31px;
    padding-left  : 0;
}

.protivopok_wrap li {
    letter-spacing : 2px;
    font-size      : 22px;
    margin-bottom  : 20px;
}

.itog_wrap li {
    letter-spacing : 2px;
    font-size      : 22px;
    margin-bottom  : 20px;
}

.itog_wrap h3 {
    text-align    : center;
    margin-bottom : 40px;
    font-family: 'Barlow Condensed', sans-serif;
}

.itog_wrap p {
    color          : #3b166a;
    font-size      : 20px;
    letter-spacing : 2px;
    font-style     : italic;
}

.rew_one_img {
    margin-bottom : 25px;
    text-align    : center;
}

.rew_one_img img {
    display : inline-block;
}

.rew_titile h3 {
    text-align    : center;
    margin-bottom : 40px;
    font-family: 'Barlow Condensed', sans-serif;
}

.rew_titile {
    text-align    : center;
    margin-bottom : 30px;
}

.rew_one_text p {
    color          : #414141;
    font-size      : 18px;
    letter-spacing : 2px;
}

.rew_one_text p span {
    color         : #000;
    position      : relative;
    font-size     : 20px;
    display       : inline-block;
    margin-bottom : 20px;
}

.rew_one_text p span:before {
    content    : '';
    position   : absolute;
    left       : 0;
    bottom     : -18px;
    width      : 80px;
    left       : calc(50% - 40px);
    height     : 1px;
    background : #000;
}

.rew {
    background : #f2f2f2;
    padding    : 65px 0;
}

.footer {
    text-align : center;
}

.foot_logo img {
    width : 150px;
}

.footer .foot_logo {
    color           : #3a2e95;
    text-decoration : none;
}

.foot_link a {
    color           : #393185;
    text-decoration : none;
    display         : inline-block;
    padding         : 10px 15px;
    letter-spacing  : 2px;
}

.about_cours {
    margin-bottom : 65px;
}

.dostup {
    margin-bottom : 65px;
}

.prog {
    margin-bottom : 40px;
}

.itog {
    margin-bottom : 65px;
}

.modal-header {
    border : none;
}

.modal-body {
    text-align : center;
}

.modal-footer {
    border          : 0;
    justify-content : center;
    padding-bottom  : 60px;
}

.itog {
    margin-bottom : 110px;
}

.rew_one {
    padding    : 0 35px;
    text-align : center;
}
.slider-for_info h2 span {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 400;
}
@media (max-width : 320px) {

}

@media (min-width : 568px) {
    .top_banner {
        background-position : 103% 100%;
    }
}

@media (min-width : 768px) {
    .pink_but {
        height     : 60px;
        width      : 216px;
        font-size  : 20px;
        margin-top : 30px;
    }

    .lozung {
        width       : 276px;
        margin-left : 46%;
        position    : relative;
    }

    .lozung:before {
        content    : '';
        position   : absolute;
        width      : 30px;
        height     : 30px;
        background : #fff;
        left       : -44px;
        bottom     : -15px;
    }

    .lozung:after {
        content    : '';
        position   : absolute;
        width      : 30px;
        height     : 30px;
        background : #fff;
        left       : -26px;
        bottom     : 7px;
    }

    .top_banner {
        background-position : 100% 100%;
        min-height          : 37vh;
    }

    .name_cours h2 {
        text-shadow : 0px 0px 11px #fff;
        width       : 345px;
        margin-left : 38%;
    }

    .top_banner .pink_but {
        height        : 60px;
        width         : 216px;
        font-size     : 20px;
        margin-top    : 0;
        margin-left   : 35%;
        margin-bottom : 30px;
    }

    .prise_one {
        min-height : 742px;
    }

    .dostup_baner p {
        font-size      : 34px;
        letter-spacing : 2px;
    }

    .rew_one {
        display         : flex !important;
        justify-content : center;
        align-items     : center;
        flex-wrap       : wrap;
    }

    .protivopok_wrap h3 {
        text-align    : center;
        margin-bottom : 40px;
    }
}

@media (min-width : 992px) {
    .box_h1 a, .footer .foot_logo a {
        font-size   : 46px;
        line-height : 43px;
    }

    .top_banner {
        background-position : 100% 100%;
    }

    .lozung:after {
        left   : -86px;
        bottom : -44px;
    }

    .name_cours h2 {
        width       : 345px;
        margin-left : 52%;
    }

    .top_banner .pink_but {
        margin-left : 53%;
    }

    .prise_one {
        min-height : 600px;
    }

    .dostup_baner {
        padding : 34px 106px;
    }

    .dostup_baner:after {
        bottom : -31px;
        right  : 89px;
    }

    .dostup_baner:before {
        top  : -76px;
        left : 9px;
    }

}

@media (min-width : 1200px) {

    .box_h1 a, .footer .foot_logo a {
        font-size   : 60px;
        line-height : 44px;
    }

    h1 {
        font-size   : 16.5px;
        border-top  : 1px solid #3a2e95;
        padding-top : 2px;
    }

    .prise_one {
        padding : 36px 16px 25px 17px;
    }

    .detailed {
        margin-bottom : 17px;
        margin-top    : 9px;
    }

    .lozung {
        width    : 347px;
        position : relative;
    }

    .lozung h2 {
        font-size : 26px;
    }

    .name_cours h2 {
        font-size   : 40px;
        margin-top  : 183px;
        width       : 522px;
        margin-left : 50%;
    }

    .name_cours h2 span {
        font-size : 80px;
    }

    .top_banner .pink_but {
        margin-left : 74%;
    }

    .prog_list {
        margin-top : 100px;
    }

    .prog_img {
        margin-bottom : -48px;
        text-align    : center;
    }

    .protivopok_wrap h3 {
        font-size : 38px;
    }

    .protivopok {
        margin-bottom : 65px;
    }

    .rew_one_text {
        padding-right : 50px;
        padding-left  : 50px;
    }

    .dostup {
        margin-top : 80px;
    }

    header {
        padding-top : 8px;
    }
}

@media (min-width : 1600px) {
    .top_banner {
        max-width        : 1600px;
        margin           : 0 auto;
    }
}

@media (min-width : 2560px) {
    .top_banner {
        max-width           : 2560px;
        background-position : 100% 25%;
    }
}

.btn_content {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 85px;
    right: 0;
}

.btn_wrapper {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    z-index: 111;
    margin-top: 30px;
}

.btn_support {
    font-weight: 700;
    font-size: 28px;
    line-height: 25px;
    background: #ff0000;
    box-shadow: 0 6px 26px rgb(250 135 0 / 40%);
    border-radius: 8px;
    color: #fff!important;
    width: 264px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: pulsebtn 0.9s infinite;
}

.btn_support_img {
    margin-right: 12px;
}

@keyframes pulsebtn {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.9);
    }
}



.wrapper {
    display        : flex;
    flex-direction : column;
    min-height     : 100%;
    margin         : 0 auto;
}

.p_blol_h1 {
    font-family: 'Anton', sans-serif;
    font-size     : 2.5rem;
    color         : #fff;
    margin-bottom : 28px;
    font-weight   : 500;
    line-height   : 1.2;
}

.content {
    flex     : 1 0 auto;
    overflow : hidden;
}

header {
    display         : block;
    align-items     : center;
    justify-content : space-between;
}

header .col-xs-12 {
    min-height : 0px;
}

.navbar-toggle .icon-bar {
    display       : block;
    width         : 22px;
    height        : 2px;
    border-radius : 1px;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color : #00619f;
}

.navbar-toggle .icon-bar + .icon-bar {
    margin-top : 4px;
}

button.navbar-toggle {
    position         : relative;
    float            : right;
    padding          : 9px 10px;
    margin-top       : 3px;
    margin-bottom    : 0px;
    background-color : transparent;
    background-image : none;
    border           : 1px solid transparent;
    border-radius    : 4px;
    outline          : none;
}

button.navbar-toggle:focus {
    outline : none;
}

.nav > li {
    position : relative;
    display  : block;
}

.nav {
    padding-left : 0;
    list-style   : none;
}

.navbar-header {
    display : inline-block;
    width   : 100%;
}

.navbar-brand img {
    width : 120px;
}

.navbar-nav li a {
    color       : #000;
    margin      : 6px 0;
    display     : inline-block;
    font-weight : 500;
}

.navbar-nav li a:hover {
    text-shadow     : 0px 0px 4px #fff;
    text-decoration : none;
}

.navbar-nav li {
    margin-left : 10px;
}

.navbar {
    padding : .5rem .5rem .5rem 1rem;
}

.mebu_name p {
    margin-top : 15px;
    color      : #00619f;
}

.box_one_ban {
    position : relative;
    overflow : hidden;
    width    : 50%;
    float    : left;
}

.phone_top span {
    display     : block;
    font-weight : 700;
}

.box_one_ban p {
    position    : absolute;
    top         : 20px;
    background  : #ffffffb8;
    left        : 0;
    z-index     : 1;
    font-size   : 18px;
    padding     : 5px 17px;
    width       : 100%;
    min-height  : 65px;
    display     : flex;
    align-items : center;
    font-weight : 500;
}

.box_one_ban img {
    width      : 100%;
    transform  : scale(0.99);
    transition : all 200ms ease;
}

.box_one_ban:hover img {
    transform  : scale(1);
    filter     : brightness(0.5);
    transition : all 200ms ease;
}

.box_one_ban a {
    position : absolute;
    top      : 0;
    left     : 0;
    width    : 100%;
    height   : 100%;
    z-index  : 2;
}

.blue_but {
    color           : #fff;
    background      : #48a8f7;
    display         : inline-block;
    text-decoration : none;
    outline         : none;
    border          : none;
    border-radius   : 5px;
    padding         : 5px 18px;
    text-align      : center;
    transition      : all 200ms ease;
}

.blue_but:hover {
    color           : #fff;
    text-decoration : none;
    box-shadow      : 0px 0px 20px #48a8f7;
    transition      : all 200ms ease;
}

.blue_but.blue_but_sm {
    width          : 100%;
    font-size      : 16px;
    padding        : 10px 20px;
    transition     : all 200ms ease;
    text-transform : uppercase;
}

.bord_but_w {
    border : 2px solid #fff;
}

.img_box img {
    width : 100%;
}

.bg_grey {
    background : #e8e8e8;
}

.svg_style {
    width : 100%;
}

.slidpro_img img {
    width      : 100%;
    box-shadow : 0px 0px 4px #676767;
}

.white_but {
    text-transform  : capitalize;
    color           : #000000;
    background      : #fff;
    border          : 1px solid #00619f;
    display         : inline-block;
    text-decoration : none;
    outline         : none;
    border-radius   : 40px;
    padding         : 8px 20px;
    width           : auto;
    text-align      : center;
    font-size       : 14px;
    transition      : all 200ms ease;
}

.white_but:hover {
    color           : #00619f;
    text-decoration : none;
    box-shadow      : 0px 0px 10px #00619f;
    transition      : all 200ms ease;
}

.b_grey_but {
    font-size  : 14px;
    background : #ddd;
    padding    : 16px 40px;
    border     : none;
    outline    : none;
}

.slid_clients_one img {
    width : 100%;
}

.call_button .blue_but {
    border-radius : 0;
    font-size     : 18px;
    width         : 100%;
}

.top_info {
    margin-top : 15px;
}

.top_contact p {
    margin-bottom : 0;
}

.top_contact p a {
    color       : #000;
    font-size   : 20px;
    font-weight : 600;
}

.navbar-collapse {
    flex-basis : auto;
    opacity    : 1;
}

.phone_top {
    margin-bottom : 10px;
    margin-top    : 20px;
}

*[id^="ymaps"] {
    min-height : 300px;
}

@media (max-width : 767px) {
    .offcanvas-stop-scrolling {
        height   : 100%;
        overflow : hidden
    }

    .navbar-default .navbar-offcanvas {
        background-color : #f8f8f8
    }

    .navbar-inverse .navbar-offcanvas {
        background-color : #222
    }

    .navbar-offcanvas {
        position                   : fixed;
        width                      : 100%;
        max-width                  : 250px;
        left                       : -250px;
        top                        : 0;
        padding-left               : 15px;
        padding-right              : 15px;
        z-index                    : 999;
        overflow                   : scroll;
        -webkit-overflow-scrolling : touch;
        -webkit-transition         : all 0.15s ease-in;
        transition                 : all 0.15s ease-in
    }

    .navbar-offcanvas.in {
        box-shadow : 0 0 20px rgba(0, 0, 0, 0.3)
    }

    .navbar-offcanvas.navbar-offcanvas-fade {
        opacity : 0
    }

    .navbar-offcanvas.navbar-offcanvas-fade.in {
        opacity : 1
    }

    .navbar-offcanvas.offcanvas-transform.in {
        -webkit-transform : translateX(250px);
        -ms-transform     : translateX(250px);
        transform         : translateX(250px);
        opacity           : 1;
    }

    .navbar-offcanvas.offcanvas-position.in {
        left : 0
    }

    .navbar-offcanvas.navbar-offcanvas-right {
        left  : auto;
        right : -250px
    }

    .navbar-offcanvas.navbar-offcanvas-right.offcanvas-transform.in {
        -webkit-transform : translateX(-250px);
        -ms-transform     : translateX(-250px);
        transform         : translateX(-250px)
    }

    .navbar-offcanvas.navbar-offcanvas-right.offcanvas-position.in {
        left  : auto;
        right : 0
    }

    .navbar-offcanvas .dropdown.active .caret {
        border-top    : 0;
        border-bottom : 4px solid
    }

    .navbar-offcanvas .dropdown-menu {
        position           : relative;
        width              : 100%;
        border             : inherit;
        box-shadow         : none;
        -webkit-transition : height 0.15s ease-in;
        transition         : height 0.15s ease-in
    }

    .navbar-offcanvas .dropdown-menu.shown {
        display       : block;
        margin-bottom : 10px
    }
}

.offcanvas-toggle {
    background : #ffffff00;
}

.offcanvas-toggle .icon-bar {
    background         : #000;
    -webkit-transition : all .25s ease-in-out;
    transition         : all .25s ease-in-out
}

.offcanvas-toggle.is-open .icon-bar:nth-child(1) {
    -webkit-transform : rotate(45deg) translate(5px, 4px);
    -ms-transform     : rotate(45deg) translate(5px, 4px);
    transform         : rotate(45deg) translate(5px, 4px)
}

.offcanvas-toggle.is-open .icon-bar:nth-child(2) {
    opacity : 0
}

.offcanvas-toggle.is-open .icon-bar:nth-child(3) {
    -webkit-transform : rotate(-45deg) translate(4px, -4px);
    -ms-transform     : rotate(-45deg) translate(4px, -4px);
    transform         : rotate(-45deg) translate(4px, -4px)
}

.svg_img {
    width : 100%;
}

.svg_img use {
    width : 100%;
}

.head_menu_logo {
    color          : #3ccdb0;
    font-size      : 25px;
    text-transform : uppercase;
}

.bg_bloc_big {
    background: #000;
    background-image: url("../images/fon111.jpeg");
    background-size : cover;
}

.head_menu_login img {
    width        : 16px;
    margin-right : 5px;
}

.head_menu_login a {
    color           : #fff;
    text-decoration : none;
    font-size       : 18px;
}

.head_menu {
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    padding-top     : 10px;
    padding-bottom  : 10px;
}

.top_ban_titl h1 {
    color         : #fff;
    margin-bottom : 28px;
}

.top_ban_titl h2 {
    color          : #3ccdb0;
    text-transform : uppercase;
    font-size      : 50px;
}

.bott_ban_tit {
    color         : #fff;
    margin-bottom : 30px;
}

.p_min {
    font-size : 22px;
}

.p_min span {
    color : #3ccdb0;
}

.p_blol {
    font-size : 28px;
}

.p_blol span {
    background : #3ccdb0;
    color      : #000;
}

.bubbly-button {
    border                            : none;
    cursor                            : pointer;
    position                          : relative;

    overflow                          : hidden;
    transition                        : transform ease-in 3s, box-shadow ease-in 0.25s;
    animation-name                    : pulse;
    -webkit-animation-name            : pulse;
    animation-duration                : 3s;
    -webkit-animation-duration        : 3s;
    animation-iteration-count         : infinite;
    -webkit-animation-iteration-count : infinite;
}

.bubbly-button:after {
    background-image                  : linear-gradient(71deg, rgba(255, 255, 255, .0) 43%, rgba(255, 255, 255, 0.64), rgba(255, 255, 255, .0) 70%);
    background-repeat                 : no-repeat;
    background-size                   : 200% 100%, auto, 100% 2px, 100% 2px, 100% 1px, 100% 1px;
    background-position               : 200% 0, 0 0, 0 0, 0 100%, 0 4px, 0 calc(100% - 4px);
    margin-left                       : 60px;
    content                           : " ";
    width                             : 30px;
    height                            : 300px;
    background                        : #ffffffc4;
    box-shadow                        : 0px 0px 22px #fff;
    -webkit-animation-delay           : 0.05s;
    animation-delay                   : 0.05s;
    position                          : absolute;
    left                              : -40px;
    top                               : -150px;
    -webkit-animation-timing-function : ease-in-out;
    animation-timing-function         : ease-in-out;
    transition                        : all 0.1s;
    -moz-transition                   : all 0.1s;
    -webkit-transition                : all 0.1s;
    -o-transition                     : all 0.1s;
    -khtml-transition                 : all 0.1s;
    -ms-transition                    : all 0.1s;
    -webkit-animation-name            : tossing;
    animation-name                    : tossing;
    -webkit-animation-duration        : 3s;
    animation-duration                : 3s;
    -webkit-animation-iteration-count : infinite;
    animation-iteration-count         : infinite;
    transform                         : rotate(45deg);
    -moz-transform                    : rotate(45deg);
    -webkit-transform                 : rotate(45deg);
    -o-transform                      : rotate(45deg);
    -khtml-transform                  : rotate(45deg);
    -ms-transform                     : rotate(45deg);
}

.bubbly-button:focus {
    outline : 0;
}

.keep {
    text-transform : uppercase;
}

.pink_but, .modal-footer button {
    background      : #3ccdb0;

    height          : 58px;
    color           : #fff;
    font-weight     : 500;
    width           : 197px;
    border-radius   : 30px;
    display         : inline-flex;
    justify-content : center;
    align-items     : center;
    font-size       : 25px;
    text-decoration : none;
    margin-top      : 0px;
}

.pink_but:hover, .modal-footer button {
    color           : #fff !important;
    text-decoration : none;
}

.pulse {
    animation-duration                : 0.5s;
    -webkit-animation-duration        : 0.5s;
    animation-iteration-count         : infinite;
    -webkit-animation-iteration-count : infinite;
}

.pulse, .pulse:active, .pulse:focus, .pulse:hover {
    animation-duration                : 0.5s;
    -webkit-animation-duration        : 0.5s;
    animation-iteration-count         : infinite;
    -webkit-animation-iteration-count : infinite;
}

@keyframes tossing {
    0% {
        transition : .05s linear;
        left       : -20px;
    }
    20% {
        transition : .05s linear;
        left       : 100%;
    }
    100% {
        transition : .05s linear;
        left       : 100%;
    }
}

@keyframes pulse {
    0% {
        -webkit-transform : scale(1.0);
        transform         : scale(1.0);
    }
    50% {
        -webkit-transform : scale(1.1);
        transform         : scale(1.1);
    }
    0% {
        -webkit-transform : scale(1.0);
        transform         : scale(1.0);
    }
}

.header_top {
    margin-bottom : 20px;
}

.top_banner {
    padding-bottom : 40px;
    margin-bottom  : 30px;
}

.results_slid_one img {
    max-width : 93%;
}



svg.svg-sli {
    width        : 14px;
    margin-right : 10px;
    height       : 36px;
}

li.prohibited svg.svg-sli {
    fill : #9498a4;
}

li.prohibited {
    color : #9498a4;
}

p.loz_prise {
    color         : #3c4e65;
    font-size     : 30px;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 600;
    text-align    : center;
    margin-bottom : 30px;
}

p.loz_prise span {
    color : #3ccdb0;
}

.title h3 {
    font-family: 'Anton', sans-serif;
    font-size  : 45px;
    text-align : center;
    color      : #3ccdb0;
}

.title h3 span {
    display   : block;
    color     : #273547;
    font-size : 35px;
}

.title {
    margin-bottom : 30px;
}

.how_lose_one_img img {
    max-width : 100%;
}

.how_lose_one_text {
    text-align : center;
}

.how_lose_one {
    margin-bottom : 25px;
}

.how_lose_one_img {
    margin-bottom : 15px;
}

.loz_how {
    color         : #3c4e65;
    font-size     : 30px;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 600;
    text-align    : center;
    margin-bottom : 30px;
}

.how_lose .pink_but {
    background : #82618c;
    height     : 48px;
    padding    : 0 20px;
    width      : auto;
    font-size  : 20px;
}

.how_lose {
    margin-bottom : 54px;
}

.what_you_img img {
    max-width : 100%;
}

.what_you_img {
    margin-bottom : 40px;
    position      : relative;
}

.what_you_img:before {
    content         : "";
    background      : url("../images/ico1.jpeg") no-repeat;
    background-size : cover;
    width           : 30px;
    height          : 30px;
    position        : absolute;
    bottom          : -13px;
    left            : calc(50% - 15px);
    transform       : rotate(90deg);
}

.what_you_text h3 {
    position : relative;
}

.what_you_text h3 span {
    z-index  : 2;
    position : relative;
    font-family: 'Anton', sans-serif;
}

.ico_01:before {
    content         : "";
    background      : url("../images/01.jpeg") no-repeat;
    background-size : cover;
    width           : 50px;
    height          : 50px;
    position        : absolute;
    top             : -23px;
}

.ico_02:before {
    content         : "";
    background      : url("../images/02.jpeg") no-repeat;
    background-size : cover;
    width           : 56px;
    height          : 50px;
    position        : absolute;
    top             : -23px;
}

.ico_03:before {
    content         : "";
    background      : url("../images/03.jpeg") no-repeat;
    background-size : cover;
    width           : 56px;
    height          : 50px;
    position        : absolute;
    top             : -23px;
}

.ico_04:before {
    content         : "";
    background      : url("../images/04.jpeg") no-repeat;
    background-size : cover;
    width           : 56px;
    height          : 50px;
    position        : absolute;
    top             : -23px;
}

.what_you_text ul {
    list-style-type : none;
    padding         : 0;
}

.what_you_text ul li {
    margin-bottom : 20px;
}

.what_you_text ul svg.svg-sli {
    fill : #3ccdb0;
}

.loz_how span {
    color : #3ccdb0;
}

.what_you .pink_but {
    background : #82618c;
    height     : 48px;
    padding    : 0 20px;
    font-size  : 20px;
}

.what_you {
    margin-bottom : 40px;
}

.you_can {
    background      : url("../images/fon3.jpeg") no-repeat;
    background-size : cover;
    padding         : 30px 0;
}

.tou_can_text {
    color      : #fff;
    text-align : center;
}

.tou_can_text span {
    background : #3ccdb0;
}

.you_can .title h3 {
    font-size  : 45px;
    text-align : center;
    color      : #575757a3;
}

.results {
    background      : url("../images/fon4.jpeg") no-repeat;
    background-size : cover;
    padding         : 30px 0;
    margin-bottom   : 30px;
    padding-bottom  : 50px;
}

.res_tit {
    color      : #fff;
    text-align : justify;
}

.res_tit span {
    background : #3ccdb0;
}

.results .title h3 {
    font-size  : 45px;
    text-align : center;
    color      : #9797976e;
}

.results .pink_but {
    background : #82618c;
    height     : 48px;
    padding    : 0 20px;
    font-size  : 20px;

}

.advantages_one ul {
    list-style-type : none;
    padding         : 0;
}

.advantages_one ul svg.svg-sli {
    fill : #3ccdb0;
}

.obvod {
    border        : 5px solid #3ccdb0;
    padding       : 30px;
    margin-bottom : 20px;
}

.advantages .pink_but {
    background : #82618c;
    height     : 48px;
    padding    : 0 20px;
    font-size  : 20px;

}

.obg_loz_one {
    background    : #3ccdb0;
    color         : #fff;
    text-align    : right;
    padding       : 10px;
    padding-right : 55px;
    font-family: 'Barlow Condensed', sans-serif;
    font-size     : 22px;
    clip-path     : polygon(0% 0%, 100% 0%, 87% 100%, 0% 100%);
}

.obg_loz_to {
    background   : #273547;
    color        : #fff;
    text-align   : left;
    padding      : 10px;
    padding-left : 55px;
    font-family: 'Barlow Condensed', sans-serif;
    font-size    : 22px;
    clip-path    : polygon(0 1%, 100% 0%, 100% 99%, 13% 100%);
    margin-top   : -1px;
}

.obg_loz {
    margin-top    : 30px;
    margin-bottom : 30px;
}

.slick-dots li.slick-active button:before {
    opacity : 1;
    color   : #3ccdb0;
}

.slick-dots li button:before {
    font-size : 30px !important;
    opacity   : 1;
    color     : #0b2033;
}

.results_pip {
    margin-bottom : 30px;
}

.rew_one_img img {
    display : inline-block;
    margin  : 0 auto;
}

.rew_one_img {
    text-align : center;

}

.rew_one_img p {
    margin-top  : 14px;
    font-family: 'Barlow Condensed', sans-serif;
    font-size   : 22px;
}

.rew_one_text {
    text-align : center;
}

.reviews {
    margin-bottom : 30px;
}

.box_h1 a {
    width         : auto;
    text-align    : left;
    font-family:  'Barlow Condensed', sans-serif !important;
    font-weight: 600 !important;
    font-size     : 23px;
    line-height   : 28px;
    color         : #3ccdb0 !important;
    border-bottom : 1px solid #3ccdb0 !important;
}

.box_h1 h2 {
    border-top-style : none;
    color            : #fff;
    font-size        : 22px;
    margin-top       : 2px;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 600;
}

.footer_link a {
    color   : #7f93ab;
    display : inline-block;
    padding : 0 12px;
}

.modal-header {
    border : none;
}

.modal-body {
    text-align : center;
}

.modal-footer {
    border          : 0;
    justify-content : center;
    padding-bottom  : 60px;
}

@media (min-width : 568px) {
    .what_you_img.bef_rig:before {
        content         : "";
        background      : url("../images/ico1.jpeg") no-repeat;
        background-size : cover;
        width           : 30px;
        height          : 30px;
        position        : absolute;
        bottom          : calc(50% - 15px);
        left            : auto;
        right           : -16px;
        transform       : rotate(0deg);
    }

    .what_you_img.bef_lef:before {
        content         : "";
        background      : url("../images/ico1.jpeg") no-repeat;
        background-size : cover;
        width           : 30px;
        height          : 30px;
        position        : absolute;
        bottom          : calc(50% - 15px);
        right           : auto;
        left            : -16px;
        transform       : rotate(180deg);
    }

    .obvod {
        position : relative;
    }

    .obvod:before {
        content         : "";
        background      : url("../images/pip1.png") no-repeat;
        background-size : cover;
        width           : 120px;
        height          : 100%;
        position        : absolute;
        bottom          : 0;
        right           : -73px;
    }

    .obg_loz_one {
        width : 65%;
    }

    .obg_loz_to {
        width : 65%;
        float : right;
    }

    .obg_loz {
        display : inline-block;
        width   : 100%;
    }
}

@media (min-width : 768px) {
    .navbar-toggle {
        display : none;
    }

    .navbar-nav > li {
        float : left;
    }

    .navbar-nav {
        float        : right;
        margin       : 0;
        display      : block;
        margin-right : 7px;
    }

    .navbar-nav li a {
        color  : #000;
        margin : 0px 0;
    }

    .navbar-header {
        width : auto;
    }

    *[id^="ymaps"] {
        min-height : 100%;
    }

    .obvod {
        width         : 85%;
        margin        : 0 auto;
        margin-bottom : 20px;
    }
}

@media (min-width : 992px) {

    .top_logo img {
        width  : auto;
        height : 64px;
    }

    .top_info {
        padding-left : 30px;
    }

    .navbar-nav .blue_but {
        padding   : 9px 15px;
        font-size : 16px;
    }

    .navbar-nav {
        margin          : 0;
        display         : flex;
        margin-right    : 0;
        width           : 100%;
        flex-direction  : row;
        justify-content : flex-end;
        align-items     : center;
    }

    .navbar-nav li {
        margin-left  : 11px;
        margin-right : 0;
    }

    .navbar-nav li.phone_top {
        margin-left : 40px;
    }

    .link_catal {
        color         : #003b7a;
        border-radius : 5px;
        border        : 2px solid #003b7a;
        padding       : 5px 10px;
    }

    .prise_one ul {
        font-size : 16px;
    }

    .what_you_text {
        display         : flex;
        justify-content : center;
        align-items     : center;
        height          : 100%;
    }

    .tou_can_text {
        width       : 40%;
        margin      : 0 auto;
        text-align  : justify;
        margin-left : 44%;
    }

    .res_tit {
        width  : 50%;
        margin : 0 auto;
    }

    .obvod {
        width : 62%;
    }

    .obg_loz_one {
        padding-right : 108px;
        clip-path     : polygon(0% 0%, 100% 0%, 96% 100%, 0% 100%);
    }

    .obg_loz_to {
        padding-left : 108px;
        clip-path    : polygon(0 1%, 100% 0%, 100% 99%, 6% 100%);
    }

}

@media (min-width : 1200px) {
    .foot_info {
        width  : 397px;
        margin : 0 auto;
    }

    .footer {
        padding-top : 0;
    }

    .footer_bottom .container-fluid {
        padding-right : 0;
    }

    .footer_bottom .container-fluid .row {
        margin-right : 0;
    }

    .what_you .container {
        width : 878px;
    }

    .obvod:before {
        content         : "";
        background      : url("../images/pip1.png") no-repeat;
        background-size : cover;
        width           : 163px;
        height          : 131%;
        position        : absolute;
        bottom          : 0;
        right           : -116px;
    }

    .obvod {
        width : 50%;
    }

    .reviews_slid {
        width  : 60%;
        margin : 0 auto;
    }

    .accordion {
        width  : 700px;
        margin : 0 auto;
    }

    .how_lose {
        margin-bottom : 87px;
        margin-top    : 60px;
    }

    .what_you {
        margin-bottom : 77px;
    }

    .obg_loz {
        display : inline-block;
        width   : 100%;
    }

    .title h3 {
        font-size  : 74px;
        text-align : center;
        color      : #3ccdb0;
    }

    .you_can {
        padding : 111px 0;
    }

    .tou_can_text {
        font-size : 21px;
    }

    .res_tit {
        font-size : 21px;
    }

    .advantages_one ul li {
        font-size : 18px;
    }

    .obg_loz {
        margin-top    : 70px;
        margin-bottom : 70px;
    }

    .you_can .title h3 {
        font-size : 80px;
    }

    .results .title h3 {
        font-size : 81px;
    }

    .prise_block .container {
        width : 862px;
    }

    .prise_block2 .container {
        width : 862px;
    }

    .prise_block3 .container {
        width : 862px;
    }

    .prise_block4 .container {
        width : 862px;
    }

    .box_h1 a {
        font-size : 35px;
    }

    .box_h1 h2 {
        font-size : 33px;
    }
}


.modal-footer button:disabled {
    background : lightgray;
    color      : black;
}

.input_error {
    border : 1px solid red !important;
}

.form-check-input {
    margin-top : 0.4rem;
}

@media (max-width: 514px) {

    .btn_content {
        right: 15px;
    }

    .btn_support {
        width: 200px;
        height: 50px;
        font-size: 22px;
        margin-right: -10px;
    }
}