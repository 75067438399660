.terms-wrapper {
    max-width: 660px;
    margin: 0 auto;
}

h1 {
    font-family: 'Anton',sans-serif;
    font-size: 2.5rem;
}

h3 {
    font-family: 'Anton',sans-serif;
    font-size: 1.75rem;
}

.link {
    text-decoration: none;
}

@media (min-width: 968px) {
    .terms-wrapper {
        max-width: 930px;
        margin: 0 auto;
    }
}