.row {
    padding-top: 0 !important;
}

.custom_wrapper {
    max-width: 600px;
    margin: 0 auto;
}

.custom-top {
    background: #6f5ee1;
}

.custom-top-text {
    display: block;
    text-align: center;
    padding: 10px 15px;
    color: white;
    font-weight: 600;
    font-size: 21px;
    text-decoration: none;
}

.custom-top-text:hover {
    color: white;
    text-decoration: none;
}

.bg_top {
    display: none !important;
}

.test-error {
    color: red;
    display: block;
}

.col-12 {
    flex: 0 0 100%;
    max-height: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.lg_wid_sm.registr_log {
    margin-top: 20px;
    margin-bottom: 20px;
}

.log_in.active {
    color: #525252;
    border-bottom: 2px solid #7f39fb;
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.input_grop {
    margin-top: 35px;
}

.input_grop input {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    border: none;
    border: 1px solid #b1b1b1;
    outline: none;
    padding: 7px 10px;
    border-radius: 8px;
}

.butt_foot {
    margin-top: 30px;
    margin-bottom: 30px;
}

.violet_butt {
    background: #6f5ee1;
    color: #fff;
    border-radius: 30px;
    padding: 11px 40px;
    min-width: 126px;
    display: inline-block;
    text-align: center;
    border: none;
    outline: none;
    transition: .3s;
}

.violet_a {
    display: inline-block;
    text-decoration: underline;
    outline: none;
    border: none;
    color: #7f39fb;
    font-size: 13px;
    background: #fff;
    margin-top: 6px;
}

.butt_foot .violet_a {
    display: inline-block;
    margin-top: 30px;
}

@media(max-width: 576px) {
    .container {
        max-width: 540px;
    }
}