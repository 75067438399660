.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.79);
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    transform: scale(0);
}
.modal.active {
    transform: scale(1);
    animation: bg 1s ease;
}
.modal__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 1rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.modal-body h4 {
    font-size: 24px;
    font-family: "Oswald", sans-serif;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.modal_email {
    width: 67%;
    padding: 7px 13px;
    border: 1px solid #ddd;
}

.close {
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
    position: absolute;
    top: 0;
    right: 0;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    border: none;
    cursor: pointer;
    width: 60px;
    height: 40px;
    background-color: transparent;
}

.modal_mail {
    width   : 67%;
    padding : 7px 13px;
    border  : 1px solid #ddd;
}

.pink_but, .modal-footer button, .modal_mail {
    font-family: "Oswald", sans-serif;
}

.text {
    color: red;
    margin-top: 10px;
}

@keyframes bg {
    0% {
        background: rgba(0, 0, 0, 0.2);
    }
    100% {
        background: rgba(0, 0, 0, 0.79);
    }
}

@media (max-width: 450px) {
    .modal-body h4 {
        font-size: 20px;
        margin-bottom: 30px;
    }
}

@media (min-width: 576px) {
    .modal__content {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}