.preloader {
    position   : fixed;
    left       : 0;
    top        : 0;
    right      : 0;
    bottom     : 0;
    background : #fff;
    z-index    : 1001
}

.preloader__row {
    position    : relative;
    top         : 50%;
    left        : 50%;
    width       : 70px;
    height      : 70px;
    margin-top  : -35px;
    margin-left : -35px;
    text-align  : center;
    animation   : preloader-rotate 2s infinite linear
}

.preloader__item {
    position         : absolute;
    display          : inline-block;
    top              : 0;
    background-color : #3ccdb0;
    border-radius    : 100%;
    width            : 35px;
    height           : 35px;
    animation        : preloader-bounce 2s infinite ease-in-out
}

.preloader__item:before,
.preloader__item:after {
    content          : "";
    position         : absolute;
    height           : 50px;
    width            : 50px;
    background-color : #393185;
    border-radius    : 50%;
}

.preloader__item:before {
    top  : -25px;
    left : 0;
}

.preloader__item:after {
    left : 25px;
    top  : 0;
}

.preloader__item:last-child {
    top             : auto;
    bottom          : 0;
    animation-delay : -1s
}

@keyframes preloader-rotate {
    100% {
        transform : rotate(360deg)
    }
}

@keyframes preloader-bounce {
    0%, 100% {
        transform : scale(0)
    }
    50% {
        transform : scale(1)
    }
}